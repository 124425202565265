<template>
  <div>
    <Header2></Header2>
    <Info></Info>
    <Info2></Info2>
    <Info2a></Info2a>
    <Info2b></Info2b>
    <Info3></Info3>
    <Info4></Info4>
    <Info5></Info5>
    <Info6></Info6>
    <Info7></Info7>
    <Info8></Info8>
    <Info9></Info9>
    <Footer></Footer>
  </div>
</template>

<script>
'use strict';

import Header2 from '@/components/Header2'
import Info from '@/components/Info'
import Info2 from '@/components/Info2'
import Info2a from '@/components/Info2a'
import Info2b from '@/components/Info2b'
import Info3 from '@/components/Info3'
import Info4 from '@/components/Info4'
import Info5 from '@/components/Info5'
import Info6 from '@/components/Info6'
import Info7 from '@/components/Info7'
import Info8 from '@/components/Info8'
import Info9 from '@/components/Info9'
import Footer from '@/components/Footer'
export default {
  components: {
    Header2, Info, Info2, Info2a, Info2b, Info3, Info4, Info5, Info6, Info7, Info8, Info9, Footer
  }
}
</script>

<style lang="sass">

</style>