import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { LayoutPlugin } from 'bootstrap-vue'
import { ImagePlugin } from 'bootstrap-vue'
import BackToTop from 'vue-backtotop'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import './styles/main.sass'

Vue.use(BackToTop)

Vue.config.productionTip = true

Vue.use(BootstrapVue)

Vue.use(BootstrapVueIcons)

Vue.use(LayoutPlugin)

Vue.use(ImagePlugin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')