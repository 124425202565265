<template>
  <section id="info2b" class='info2'>
  <b-container>
    <b-row align-h="center">
      <b-row align-h="center">
      <b-col cols="12" md="10" lg="8">
      <p class="a_header" align="center">
        Lunar Gem
      </p>
      <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;">
        Some day everyone will be able to leave the monument on the Moon.
      </div>
      <div>
          <div align="center">
            <a href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-161"><img src="../assets/LunarGem.jpg" width="320"/></a>
          </div>
      </div>
      </b-col>
    </b-row>
    </b-row>
    <b-row align-h="center">
      <div style="padding: 10px">
        <b-button class="btn__succes" href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-161">Buy it now!</b-button>
      </div>
    </b-row>
  </b-container>
    <b-container>
      <b-row align-h="center">
        <b-row align-h="center">
          <b-col cols="12" md="10" lg="8">
            <p class="a_header" align="center">
              Lunar Gem After Party
            </p>
            <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;">
              Some day everyone will be able to leave the monument on the Moon. And arrange a party after it. This is the entrance ticket. Consider it!
            </div>
            <div>
              <div align="center">
                <a href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-162"><img src="../assets/LunarGem_Holiday.jpg" width="320"/></a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-row>
      <b-row align-h="center">
        <div style="padding: 10px">
          <b-button class="btn__succes" href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-162">Buy it now!</b-button>
        </div>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info2b"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>