<template>
  <footer class="footer">
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="12" g="12">
          <div style="padding-left: 35%; padding-right: 35%">
            <a
            class="btn btn__primary"
            href="https://twitter.com/TheEugeArts"
            target="_blank"
          ><svg sstyle="color: blue" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/> </svg>  Contact</a>
          </div>
        </b-col>
      </b-row>
      <b-row style="padding-top:.25rem">
        <b-col cols="12" md="12" lg="12">
          <div align="center">
            <p>Copyright Cpt.SOLO ©2022 <br>All rights reserved</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="sass">
@use '../styles/main'

.footer
  .container
    padding-top: 35px
    padding-bottom: 20px
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    background-color: main.$elements-bg
    box-shadow: 0px 0px 10px rgba(22, 22, 26, .5)
  .logo
    width: 60%
    height: 60%
    display: flex
    align-items: center
    p
      margin: 10px
  .contacts
    display: flex
    flex-direction: column
    .wrap
      width: 100%
      text-align: left
      a
        margin-left: 10px
      p
        margin-bottom: .2rem

@media screen and (max-width: 768px)
  .footer
    .logo
      justify-content: center
      margin-bottom: .5rem
    .nav
      flex-direction: column
      a
        margin-bottom: .5rem
    .contacts
      .wrap
        text-align: center
        a
          margin-right: 10px

@media screen and (max-width: 576px)
  .container
    border-top-left-radius: 5px
    border-top-right-radius: 5px
</style>