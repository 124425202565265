<template>
  <section id="info5" class='info2'>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8">
          <div style="padding: 10px" id="OpenSea">
            <i>Some of my artworks on OpenSea. This is the largest collection for now.</i>
          </div>
          <b-row align-h="center" id="OpenSea">
            <b-button class="btn__primary" href="https://opensea.io/CptSOLO_Aka_TheEuge?tab=featured" target="_blank" style="max-width: 80%">Open <img src="../assets/OpenSea_icon.svg.png" width="40" height="auto"/> Sea</b-button>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Open Sea Stranger</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Who could ever imagine, that I, former seaman, will publish my NFT of my oil painting from 1997 on OpenSea? Oil on canvas, 39,370 x 28,740 in. 1997.

                "Golden Eagle Special Achievement Medal" in nomination Fantasy at International Juried Fine Arts Exhibition, US, New York, West Park Art Gallery, 2017.</p>
              </div>
              <div align="center">
                <a href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955683562559373313/"><b-img thumbnail src="../assets/SeaStranger.jpg" style="max-width: 50%" alt="Open Sea Stranger"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955683562559373313/">Buy it now!</b-button>
            </div>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>The Portrait of Tea with Lemon</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Just the small still life "portrait" of tea in a blue cup with a lemon accompanying. And a small chocolate candy, trying to escape. Oil on canvas, 11,811 x 15,748 in., 2017 </p>
              </div>
              <div align="center">
                <a href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955703353768673281/"><b-img thumbnail src="../assets/DSC_6308_005.jpg" style="max-width: 50%" alt="The Portrait of Tea with Lemon"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955703353768673281/">Buy it now!</b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info5"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>