<template>
  <section class='info'>
    <b-container>
      <b-row>
        <b-col cols="12" md="8" lg="6">
          <div class="emptyUp">

          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="10">
          <div>
            <div>
              <b-card id="info">
                <b-row align-h="center" style="padding-bottom: 50px">
                  <b-col cols="12" md="10" lg="10">
                    <a href="https://gallery.manifold.xyz/theeuge" v-b-tooltip.hover title="Manifold Auction Gallery" style="padding: 10px"><img src="../assets/manifold.svg" width="60" height="auto"/></a><a href="https://app.copernicspace.com/" v-b-tooltip.hover title="Copernic Space" style="padding: 10px"><img src="../assets/CopernicSpace_logo_high_quality.png" width="110" height="auto"/></a> <a href="https://looksrare.org/accounts/0xbC9f8900f34c84e0f1207926c89dd2afe9d3CC72" v-b-tooltip.hover title="Looksrare" style="padding: 10px"><img src="../assets/looksrare-logo-8A0876C037-seeklogo.com.png" width="40" height="auto"/></a> <a href="https://foundation.app/@TheEuge" v-b-tooltip.hover title="Foundation" style="padding: 10px"><img src="../assets/FND_400x400.jpg" width="33" height="auto"/></a> <a href="https://opensea.io/CptSOLO_Aka_TheEuge?tab=featured" v-b-tooltip.hover title="OpenSea" style="padding: 10px"><img src="../assets/OpenSea_icon.svg.png" width="35" height="auto"/></a> <a href="https://rarible.com/theeuge" v-b-tooltip.hover title="Rarible" style="padding: 10px"><img src="../assets/CryptoMode-Rarible-Crypto-Art.png" width="35" height="auto"/></a> <a href="https://mintable.app/u/theeuge" v-b-tooltip.hover title="Mintable" style="padding: 10px"><img src="../assets/mintable.jpeg" width="28" height="auto"/></a> <a href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir" v-b-tooltip.hover title="SIGN Art" style="padding: 10px"><img src="../assets/signart.svg" width="55" height="auto"/></a> <a href="https://solsea.io/a/632afaa62481273eed328eeb" v-b-tooltip.hover title="SolSea" style="padding: 10px"><img src="../assets/new_solsea_logo.svg" width="28" height="auto"/></a>
                  </b-col>
                </b-row>
                <b-media>
                  <template #aside>
                    <b-img src="../assets/CptSOLO_NEW_SpaceHelmet3.png" width="200" alt="Cpt. SOLO"></b-img>
                  </template>

                  <h3 class="mt-0">Cpt. SOLO</h3>
                  <hr>
                  <p class="text_main">
                    Cpt. SOLO (aka TheEuge), irl Eugene E. Anisimov, he/him, traditional and digital artist, musician, composer, Web3 software developer,
                    active currently mostly in the Metaverse NFT space, based in Ukraine, husband, daddy & even granddaddy. Mostly 1/1 NFTs.
                  </p>
                  <p class="text_main">
                    Cpt's. journey through the worlds of art began back when Web1 did not yet exist. Even then, he felt
                    the need to search for meanings and images in art, which we now call memes in the NFT space. Memes are
                    the add value to the arts we create, buy and sell, making them real assets. That's why the way of pathfinding
                    in the worlds of contemporary art, especially in the NFT space, is hard but lucrative economically and rewarding
                    for creators and traders.
                  </p>
                  <p class="text_main">
                    The variety of styles and techniques is the result of multiple searches for his "gem" style.
                  </p>
                  <p class="text_main">
                    His artworks are in many private collections in the US, Germany, Austria, Cyprus, Ukraine.
                  </p>
                  <h3 class="mt-0">Why Cpt. SOLO?</h3>
                  <p class="text_main">
                    Almost 10 years of his life he devoted to maritime transportation. Also perhaps because of Solitude while exploring new worlds of art. Another meaning of that is music. He composes digital and
                    traditional musical creations. Also when the Star Wars era just began, Cpt. SOLO was immediately there, he felt himself as the part of that universe, in a galaxy, far far
                    away... Since then, and now on, the <a href="https://t.co/iMtuxz4pcF" class="alink" target="_blank">space theme</a> in its global meaning
                    is one of the most capturing and leading for him. Especially with the help of the <a href="https://app.copernicspace.com/" class="alink" target="_blank">Copernic Space</a>
                    platform which is a real breakthrough and brand new economy behind it, in the world's leading space industry with highest
                    potenial.
                  </p>
                  <p class="text_main">
                    And of course Cpt. SOLO is on <a href="https://solsea.io/a/632afaa62481273eed328eeb" class="alink" target="_blank">Solana</a> too :-).
                  </p>
                </b-media>
              </b-card>
            </div>
          </div>
        </b-col>
        </b-row>
      </b-container>
  </section>
</template>

<script>
export default {
  name: 'Info'
  }
</script>

<style lang='sass'>
@use '../styles/main'
.btn-to-top
  width: 60px
  height: 60px
  padding: 10px 16px
  border-radius: 50%
  font-size: 22px
  line-height: 22px
.emptyUp
  background-color: main.$bg
  padding-top: 350px
  //background-origin: content-box
  .container
    padding-top: 90px
.info
  background-color: main.$bg
  padding-top: 120px
  //background-origin: content-box
  .container
    padding-top: 90px
  .btn__primary
    width: 40%
.alink
  color: #4fc4cf
  text-decoration-color: #4fc4cf
  text-underline: #5c40cc
@media screen and (max-width: 576px)
  .emptyUp
    padding-top: 30px
  .info
    padding-top: 145px
    padding-left: 20px
    padding-right: 20px
@media screen and (max-width: 768px)
  .emptyUp
    padding-top: 30px
  .info
    padding-top: 140px
    padding-left: 20px
    padding-right: 20px
</style>
