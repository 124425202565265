<template>
  <section id="info2" class='info2'>
  <b-container>
    <b-row align-h="center">
      <b-col cols="12" md="10" lg="8">
        <div><h3 class="mt-0">ARTs</h3></div>
        <hr>
        <h5 class="mt-0" style="padding: 10px">My artworks on different platforms and blockchain networks</h5>
        <b-row align-h="center">
        <b-col cols="12" md="12" lg="12">
          <a href="https://gallery.manifold.xyz/theeuge" v-b-tooltip.hover title="Manifold Auction Gallery" style="padding: 10px"><img src="../assets/manifold.svg" width="60" height="auto"/></a><a href="#CopSpace" v-b-tooltip.hover title="Copernic Space" style="padding: 10px"><img src="../assets/CopernicSpace_logo_high_quality.png" width="110" height="auto"/></a> <a href="#Looksrare" v-b-tooltip.hover title="Looksrare" style="padding: 10px"><img src="../assets/looksrare-logo-8A0876C037-seeklogo.com.png" width="40" height="auto"/></a> <a href="#FND" v-b-tooltip.hover title="Foundation" style="padding: 10px"><img src="../assets/FND_400x400.jpg" width="33" height="auto"/></a> <a href="#OpenSea" v-b-tooltip.hover title="OpenSea" style="padding: 10px"><img src="../assets/OpenSea_icon.svg.png" width="35" height="auto"/></a> <a href="#Rarible" v-b-tooltip.hover title="Rarible" style="padding: 10px"><img src="../assets/CryptoMode-Rarible-Crypto-Art.png" width="35" height="auto"/></a> <a href="#Mintable" v-b-tooltip.hover title="Mintable" style="padding: 10px"><img src="../assets/mintable.jpeg" width="28" height="auto"/></a> <a href="#SIGNart" v-b-tooltip.hover title="SIGN Art" style="padding: 10px"><img src="../assets/signart.svg" width="55" height="auto"/></a> <a href="#SolSea" v-b-tooltip.hover title="SolSea" style="padding: 10px"><img src="../assets/new_solsea_logo.svg" width="28" height="auto"/></a>
        </b-col>
        </b-row>
        <div style="padding: 15px">
        <hr>
        </div>
        <div style="padding: 10px">
          <i>Copernic Space, Polygon driven Spaceibles Marketplace & Community</i>
        </div>
        <b-row align-h="center" id="#CopSpace">
          <b-button class="btn__primary" href="https://app.copernicspace.com/" target="_blank" style="max-width: 80%"><img src="../assets/CopernicSpace_logo_high_quality.png" width="130" height="auto"/></b-button>
        </b-row>
        <b-row align-h="center">
          <p class="a_header">
            The Call
          </p>
          <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;">
            The Universe is listening to us now, It listens always, It responses with the best we ask for. The only thing is to ask it with full heart and depth of your soul. And it will give you all you ask, multiplied by 1000. Let's ask the Universe, the God, everyday, day by day, for peace, mercy and love.
          </div>
          <div>
            <b-col cols="12" md="10" lg="8">
              <div>
              <video embed-responsive embed-responsive-1by1 controls poster="../assets/TheCall_Promo_CopernicSpace.jpg" width="320">
                <source src="../assets/TheCall_New_1080_60.mp4" type="video/mp4">
              </video>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row align-h="center">
          <div style="padding: 10px">
            <b-button class="btn__succes" href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-170">Buy it now!</b-button>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
  </section>
</template>

<script>
export default {
  name: "Info2"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

.a .title
  font-size: x-small

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>