<template>
  <section id="info5" class='info2'>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8">
          <div style="padding: 10px" id="Mintable">
            <i>My artworks on Mintable.</i>
          </div>
          <b-row align-h="center" id="Mintable">
            <b-button class="btn__primary" href="https://mintable.app/u/theeuge" target="_blank" style="max-width: 80%">Mintable <img src="../assets/mintable.jpeg" width="40" height="auto"/></b-button>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Dog the Butler</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Originally created in 1998. The tribute of mine to my dog Fritz of Boxer breed.
                Hand painted art-piece of mine. 1/1 NFT.
                The first buyer gets the original work (free shipping worldwide).
                Gouashe / water colors on aged carton, 40 x 30 cm (15,748 x 11,811 in.), 1998.</p>
              </div>
              <div align="center">
                <a href="https://mintable.app/ART/item/Dog-the-Butler-Crypto-Dog-from-the-Past/tNIK2a7QVQ2egUo"><b-img thumbnail src="../assets/Doggy_Thumb.jpg" style="max-width: 50%" alt="Dog the Butler"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://mintable.app/ART/item/Dog-the-Butler-Crypto-Dog-from-the-Past/tNIK2a7QVQ2egUo">Buy it now!</b-button>
            </div>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Cat the Fisherman</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Originally created in 1998. The tribute of mine to my saved cat Hamir El Evizaar of Persian breed.
                Hand painted art-piece of mine. 1/1 NFT.
                The first buyer gets the original work (free shipping worldwide).
                Gouashe / water colors on aged carton, 40 x 30 cm (15,748 x 11,811 in.), 1998. </p>
              </div>
              <div align="center">
                <a href="https://mintable.app/ART/item/Cat-the-Fisherman-Crypto-Cat-from-the-Past/Jh2sxbrBHhypaxS"><b-img thumbnail src="../assets/CatFisherman_Thumb.jpg" style="max-width: 50%" alt="Cat the Fisherman"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://mintable.app/ART/item/Cat-the-Fisherman-Crypto-Cat-from-the-Past/Jh2sxbrBHhypaxS">Buy it now!</b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info7"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>