<template>
  <section id="info3" class='info2'>
  <b-container>
    <b-row align-h="center">
      <b-col cols="12" md="10" lg="8">
        <div style="padding: 10px" id="Looksrare">
          <i>My artworks on LOOKSRARE (my manifold sovereign contract)</i>
        </div>
        <b-row align-h="center">
          <b-button class="btn__primary" href="https://looksrare.org/accounts/0xbC9f8900f34c84e0f1207926c89dd2afe9d3CC72" target="_blank" style="max-width: 80%">LOOKS<img src="../assets/looksrare-logo-8A0876C037-seeklogo.com.png" width="50" height="auto"/>RARE</b-button>
        </b-row>
        <b-row align-h="center">
          <div>
            <b-row align-h="center">
            <div class="a_header" style="padding: 5px; position: center"><p>The Door</p></div>
            </b-row>
            <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>My original hand made oil painting on canvas, 40 x 60 cm (15,748 x 23,622 in.), Limassol, Cyprus, 2017</p>
            </div>
            <div align="center">
              <a href="https://looksrare.org/collections/0x88EB911d0a18ee4e2ee2000404da976353E491B4/1"><b-img thumbnail src="https://arweave.net/3h_aP1sk1opFT_zVKUFojHwDJi8emH-O67h3DH4MZSw" style="max-width: 50%" alt="The Door"></b-img></a>
            </div>
          </div>
        </b-row>
        <b-row align-h="center">
          <div style="padding: 10px">
            <b-button class="btn__succes" href="https://looksrare.org/collections/0x88EB911d0a18ee4e2ee2000404da976353E491B4/1">Buy it now!</b-button>
          </div>
        </b-row>
        <b-row align-h="center">
          <div>
            <b-row align-h="center">
              <div class="a_header" style="padding: 5px; position: center"><p>Morning Coffee</p></div>
            </b-row>
            <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Early sunny morning after her bath. A cup of coffee and plans for the new day.

              Original oil painting on canvas, 60 x 40 cm (23,622 x 15,748 in.), 2016 </p>
            </div>
            <div align="center">
              <a href="https://looksrare.org/collections/0x88EB911d0a18ee4e2ee2000404da976353E491B4/2"><b-img thumbnail src="https://arweave.net/6xHaj6VutY9vtlwE3oidFqJ4g0lWC7kwHxswB6OvqWc" style="max-width: 50%" alt="The Door"></b-img></a>
            </div>
          </div>
        </b-row>
        <b-row align-h="center">
          <div style="padding: 10px">
            <b-button class="btn__succes" href="https://looksrare.org/collections/0x88EB911d0a18ee4e2ee2000404da976353E491B4/2">Buy it now!</b-button>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <hr>
  </b-container>
  </section>
</template>

<script>
export default {
  name: "Info3"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>