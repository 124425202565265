<template>
  <section id="info2a" class='info2'>
  <b-container>
      <b-row align-h="center">
        <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;">
          <p class="a_header" align="center">
            The Call Promo Ticket
          </p>
          The promo ticket for the Call (The Call by ++Cpt. SOLO++) to the Universe, praying for Peace, Mercy and Love.
        </div>
        </b-row>
    <b-row align-h="center">
        <div>
          <b-col cols="12" md="10" lg="8">
            <div>
              <a href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-169"><img src="../assets/TheCall_Promo_CopernicSpace.jpg" width="320"/></a>
            </div>
          </b-col>
        </div>
      </b-row>
      <b-row align-h="center">
        <div style="padding: 10px">
          <b-button class="btn__succes" href="https://app.copernicspace.com/spaceibles/asset/0xAA54295c8eb420732a3748414B782C89229C0841-169">Buy it now!</b-button>
        </div>
      </b-row>
  </b-container>
  </section>
</template>

<script>
export default {
  name: "Info2a"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>