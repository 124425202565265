<template>
  <section id="info5" class='info2'>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8">
          <div style="padding: 10px" id="Rarible">
            <i>My artworks on Rarible.</i>
          </div>
          <b-row align-h="center" id="Rarible">
            <b-button class="btn__primary" href="https://rarible.com/theeuge" target="_blank" style="max-width: 80%">Rarible <img src="../assets/CryptoMode-Rarible-Crypto-Art.png" width="40" height="auto"/></b-button>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Social Lift, L. No. 99</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Hand made drawing with pencil on paper.</p>
              </div>
              <div align="center">
                <a href="https://rarible.com/token/0xf6793da657495ffeff9ee6350824910abc21356c:85316689781825719419681108300472023226833040213050967667502955675865977978881?tab=overview"><b-img thumbnail src="../assets/2017001_resize_rioted.jpg" style="max-width: 50%" alt="Open Sea Stranger"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955683562559373313/">Buy it now!</b-button>
            </div>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Social Lift, L. No. -99</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Hand made drawing with pencil on paper. </p>
              </div>
              <div align="center">
                <a href="https://rarible.com/token/0xf6793da657495ffeff9ee6350824910abc21356c:85316689781825719419681108300472023226833040213050967667502955675865977978882?tab=overview"><b-img thumbnail src="../assets/social_lift_2001_rioted_1920.jpg" style="max-width: 50%" alt="The Portrait of Tea with Lemon"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/85316689781825719419681108300472023226833040213050967667502955703353768673281/">Buy it now!</b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info6"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>