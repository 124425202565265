<template>
  <section id="info4" class='info2'>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8">
          <div style="padding: 10px" id="FND">
            <i>My artworks on Foundation</i>
          </div>
          <b-row align-h="center" id="FND">
            <b-button class="btn__primary" href="https://foundation.app/@TheEuge" target="_blank" style="max-width: 80%">FOUNDATION   <img src="../assets/FND_400x400.jpg" width="40" height="auto"/></b-button>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>I LOVE TO SEE THEM BURN collection</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Digital impressionism collection. Gradually growing series resembles this fast impression of very hot and prominent move of the fire energy, which burns all unnecessary and unwanted stuff from metaphysical perception.</p>
              </div>
              <div align="center">
                <a href="https://foundation.app/collection/teartsii"><b-img thumbnail src="../assets/ILOVETOSEETHEMBURN_FND_3.jpg" style="max-width: 50%" alt="The Door"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://foundation.app/collection/teartsii">Buy it now!</b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info4"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>