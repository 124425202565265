<template>
  <header class="second_header">
<b-container fluid>
        <b-row>
          <b-col>
            <div>
<img src="../assets/pathfinderOpenSea.png" alt="Cpt.Solo Metaverse Pathfinder" class="imgtop" />
            </div>
          </b-col>
        </b-row>
</b-container>
    <b-container style="padding-top: 15px; padding-bottom: 15px" fluid>
      <b-row align-h="center" style="padding-bottom: 15px">
        <b-col cols="10" md="6" offset-md="0" lg="3" class="d-none d-md-block">
          <nav class="nav">
            <a href="#info" style="font-size: x-large">-=[ NFT Arts & Projects ]=-</a>
          </nav>
        </b-col>
      </b-row>
      <hr>
    </b-container>
    <b-container style="padding-top: 20px; padding-bottom: 20px">
      <b-row align-h="center">
        <b-col cols="6" md="3" lg="0">
          <div class="logo"><a href="."><img src="../assets/CptSOLOLogo.png" class="imgtop"/></a></div>
        </b-col>
        <b-col cols="4" md="2" lg="0" class="a_header">
          <nav>
            <a href="#info">About me</a>
          </nav>
        </b-col>
        <b-col cols="4" md="2" lg="0" class="a_header">
            <nav>
              <a href="#info2">ARTs</a>
            </nav>
        </b-col>

        <b-col cols="4" md="2" lg="0">
          <div class="a_header">
            <a class="icon" href="https://twitter.com/TheEugeArts" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="166" height="166" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" id="mainIconPathAttribute" fill="blue" filter="url(#shadow)"></path> <filter id="shadow"><feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black"></feDropShadow></filter></svg>
            </a>
          </div>
        </b-col>
        <b-col cols="2" class="d-block d-md-none">
          <button class="btn-burger" @click="mobileMenuActive = !mobileMenuActive">
            <img src="../assets/icons/menu.svg" />
          </button>
        </b-col>
      </b-row>
    </b-container>
    <transition name="fade-slideRight">
      <div class="menu-mobile" v-if="mobileMenuActive" @click="closeModal">
        <div class="menu-mobile_content">
          <button class="btn-close" @click="mobileMenuActive = !mobileMenuActive">
            <img src="../assets/icons/close.svg" alt />
          </button>
          <nav class="menu-mobile_nav">
            <b-col cols="4" md="2" lg="0" class="a_header">
              <nav>
                <a href="#info">About me</a>
              </nav>
            </b-col>
            <b-col cols="4" md="2" lg="0" class="a_header">
              <nav>
                <a href="#info2">ARTs</a>
              </nav>
            </b-col>
          </nav>
          <div class="menu-mobile_contacts">
            <a class="icon" href="https://twitter.com/TheEugeArts" target="_blank">
              Twitter
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/> </svg>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  data: () => ({
    mobileMenuActive: false
  }),
  methods: {
    closeModal() {
      if (event.target == document.querySelector('.menu-mobile')) {
        this.mobileMenuActive = !this.mobileMenuActive
      }
    }
  }
};
</script>

<style lang="sass">
@use '../styles/main'

.header
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  box-shadow: 0px 0px 10px rgba(22, 22, 26, .5)
  position: absolute
  width: 100%
  top: 0
  z-index: 2
  padding: 10px 0

.nav
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center

.contacts
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center

.a_header
  font-weight: normal
  font-size: larger

.second_header
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  box-shadow: 0px 0px 10px rgba(22, 22, 26, .5)
  background-color: main.$elements-bg
  position: absolute
  width: 100%
  top: 0
  z-index: 2
  padding: 10px 0

.imgtop
  max-width: 100%
  height: auto

@media screen and (max-width: 576px)
  .header
    position: absolute
    padding: 5px 0
    top: 0
    background-color: #161616
    z-index: 2

@media screen and (max-width: 768px)
  .header
    position: absolute
    top: 0
    background-color: #161616
    z-index: 2
  .contacts
    padding: 15px 0
</style>