<template>
  <section id="info5" class='info2'>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8">
          <div style="padding: 10px" id="SIGNart">
            <i>My artworks on SIGN Art.</i>
          </div>
          <b-row align-h="center" id="SIGNart">
            <b-button class="btn__primary" href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir" target="_blank" style="max-width: 80%">SIGN <img src="../assets/signart.svg" width="40" height="auto"/> Art</b-button>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>The Miracle</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Signs and symbols rule this world. Signs and symbols give birth to ideas and actions. Signs and symbols change the world to all ends.

                My first artwork on SIGNart is symbolically "the Miracle", a piece from the past, from 1997. It was the time of hard changes and testaments for me, when the darkness seemed to have conquered the light at all, and this artwork was born.
                Miraculously this one got hidden even from myself until today. It's rare and was not exhibited anywhere since those times.</p>
              </div>
              <div align="center">
                <a href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir/artwork/HN4XMFaxTV2CRrS1iEPE5wDfPy3wcwJFifzdHpPvVv1n"><b-img thumbnail src="../assets/Miracle.jpeg" style="max-width: 50%" alt="The Miracle"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir/artwork/HN4XMFaxTV2CRrS1iEPE5wDfPy3wcwJFifzdHpPvVv1n">Buy it now!</b-button>
            </div>
          </b-row>
          <b-row align-h="center">
            <div>
              <b-row align-h="center">
                <div class="a_header" style="padding: 5px; position: center"><p>Early Morning Coffee</p></div>
              </b-row>
              <div class="description" style="text-align: center; padding-left: 40px; padding-right: 40px;"><p>Reminiscence of the past. Still life with coffee cup and something to eat, accompanied with a beautiful lady at about 4 a.m.

                Gouache / water colors on what-man paper, ~29 x 21 cm, 1994.</p>
              </div>
              <div align="center">
                <a href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir/artwork/6Vq6ZUkb659tZ58BesV8o2AQs4XNPUMbZJHYn1c4RXh2"><b-img thumbnail src="../assets/cupofcoffee_2893.jpg" style="max-width: 50%" alt="Early Morning Coffee"></b-img></a>
              </div>
            </div>
          </b-row>
          <b-row align-h="center">
            <div style="padding: 10px">
              <b-button class="btn__succes" href="https://mainnet.sign-art.app/user/3PBz3Kip3kJFLJGzBba5eWcDG65SGhz4vir/artwork/6Vq6ZUkb659tZ58BesV8o2AQs4XNPUMbZJHYn1c4RXh2">Buy it now!</b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Info8"
}
</script>

<style lang="sass" scoped>
.btn__succes
  max-width: 100%
  height: max-content

.description
  font-size: small
  font-style: italic
  padding: 15px
  align-content: center

@media screen and (max-width: 576px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px

@media screen and (max-width: 768px)
  .description
    padding-left: 20px
    padding-right: 20px
  .container
    padding-left: 40px
    padding-right: 40px
</style>